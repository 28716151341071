.slide{
    /* background-color: brown; */
}
.arrow{
    font-size: 24px;
    padding: 2px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 5px rgb(238, 232, 232);
    display: flex;
    width: 30px;
    position: absolute;
    top: 170px;
    z-index: 10;
    color: #f04f25;
}
.arrow-right{
    right: 0px;

}