.pagination{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 1.2rem;
    gap:5px;
    
   
   
}
.pagination .page-num{
    padding: 8px 15px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 400;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-color: #92a8d1;
    background-color: rgb(228, 228, 228);
  
}
.pagination .page-num:hover{
    background-color: #0a2357;
    color: white;
    text-align: center;
}
.pagination .active{

    background-color: #61dafb;
    color:white;
    text-align: center;
}