.zoom-in-out-box {
   
    width: 60px;
    height: 60px;
    background: #f50057;
    animation: zoom-in-zoom-out 4s ease infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }